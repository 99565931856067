import React from 'react'

import { PhoneHeader } from "../PhoneComponents/PhoneHeader";
import { PhoneFooter } from "../PhoneComponents/PhoneFooter";

import logo from '../Assets/tbdigimenu.png';

import Qrcodes from '../Assets/tbdigimenu-QR Codes.png';
import letmedine from '../Assets/LetMeDine-DigitalMenu.jpg';
import dwarksydMob from '../Assets/DwarkaSydMenu-mobile.jpg';

import { ContactForm } from '../ContactForm';

export const PhoneHome = () => {
  return (
        <>
            <PhoneHeader/>

            <div className='sectionOne'>
          <div>
            <div className='col textCol'>
              <h1>Get Digital Menu With QR Code For Your Restaurant</h1>
              <p>Aaj Hi Apne Menu Ko Digital QR Code Mein Badlein.</p>
              <a href="http://wa.me/917838550257?text=Hi%20I'm%20Interested%20In%20Digital%20Menu">WhatsApp Us</a>
            </div>
          </div>
          <div>
            <div className='col imgCol'>
              <img alt="tbdigimenu" src={Qrcodes} height="450" />
            </div>
          </div>
        </div>

        <div className='sectionTwo'>
          <div className='textBlock'>
            <div className='col'>
              <h1>Welcome To</h1>
              <img className='logo' alt="tbdigimenu" src={logo} height="60" />
              <p>Welcome to tbdigimenu – your one-stop-shop for all your digital menu needs! We are a leading provider of digital menus with QR codes, providing a modern and efficient way for businesses to present their menus to customers.</p>
              <p>Our digital menu solution is designed to meet the needs of restaurants, cafes, bars, hotels, and events. We provide a customizable digital menu that can be tailored to your unique needs, including branding, menu layout, and pricing. Our digital menus can be easily updated remotely, ensuring that your customers have access to the most up-to-date information.</p>
            </div>
          </div>
          <div>
            <div className='col imgBlock'>
              <img alt="tbdigimenu" src={letmedine} />
            </div>            
          </div>
        </div>
        
        <div className='sectionThree'>
          <div className='full'>
            <div className='col'>
              <div className='textBlock'>
                <h1>Digitize Your Restaurant With QR Code Menu With Tbdigimenu</h1>
                <p>Ready to take your menu to the next level? Contact us today to schedule a consultation and learn more about how our digital menus can benefit your business.</p>
                <hr class="divider"/>
               </div>
              <img alt="tbdigimenu" src={dwarksydMob}  />
            </div>            
          </div>
        </div>

        <div className='sectionFour'>
          <div className='MobileContactform'>
            <div className='textBlock'>
              <h1>Fill this form to get a call back from us!</h1>
              <p>We will call you back within 24 hours</p>
            </div>

            <ContactForm />

          </div>
        </div>


            <PhoneFooter/>
        </>
  )
}
