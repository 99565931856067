import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import logoWhite from '../Assets/tbdigimenu-white.png'
import whatsapp from '../Assets/whatsapp.png'
import phone from '../Assets/telephone.png';

export const Header = () => {
  return (
    <Navbar >
        <Container>
          <Navbar.Brand href="#home">
            <img
              alt="tbdigimenu"
              src={logoWhite}
              height="50"
              className="d-inline-block align-top"
            />{' '}
          </Navbar.Brand>
          <Nav className="justify-content-end">
            <Nav.Link href="tel:917838550257"><img src={phone} height="30" alt="Call Us" /></Nav.Link>
            <Nav.Link href="http://wa.me/917838550257?text=Hi%20I'm%20Interested%20In%20Digital%20Menu">
            <img src={whatsapp}  height="30" alt="Whatsapp Us" /></Nav.Link>
          </Nav>
        </Container>
      </Navbar>
  )
}
