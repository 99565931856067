import React from 'react'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import logoWhite from '../Assets/tbdigimenu-white.png'
import whatsapp from '../Assets/whatsapp.png'

export const PhoneHeader = () => {
  return (
    <Navbar >
        <Container>
          <Nav className="justify-content-center">
          <Navbar.Brand href="#home">
            <img
              alt="tbdigimenu"
              src={logoWhite}
              height="50"
              className="d-inline-block align-top"
            />{' '}
          </Navbar.Brand>
          </Nav>
          <Nav className="justify-content-end">
            <Nav.Link href="http://wa.me/917838550257?text=Hi%20I'm%20Interested%20In%20Digital%20Menu">
            <img alt="tbdigimenu-whatsapp"  src={whatsapp}  height="35" /></Nav.Link>
          </Nav>


        </Container>
      </Navbar>
  )
}
