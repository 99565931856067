import React from 'react'
import { useForm, ValidationError } from '@formspree/react';

export const ContactForm = () => {
    const [state, handleSubmit] = useForm("xyyaorjl");
    if (state.succeeded) {
        return <p className='afterSubmitText'>We will contact you soon!</p>;
    }
    return (
        <form className="contactForm" onSubmit={handleSubmit}>
            {/* <label htmlFor="full-name">
            Full Name
            </label> */}
            <input
            id="full-name"
            type="full-name" 
            name="full-name"
            placeholder='Full Name'
            />
            <ValidationError 
            prefix="Full Name" 
            field="full-name"
            errors={state.errors}
            />
            {/* <label htmlFor="telephone">
            Phone Number
            </label> */}
            <input
            id="telephone"
            type="telephone" 
            name="telephone"
            placeholder='Phone Number'
            />
            <ValidationError 
            prefix="Telephone" 
            field="telephone"
            errors={state.errors}
            />
            {/* <label htmlFor="email">
            Email Address
            </label> */}
            <input
            id="email"
            type="email" 
            name="email"
            placeholder='Email Address'
            />
            <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
            />
            <button type="submit" disabled={state.submitting}>
            Submit
            </button>
      </form>
  )
}
