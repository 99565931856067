import React from 'react'

export const Copyright = () => {
  return (
    <div className='copyright'>
        <div className='cr-row'>
            <div className='cr-left'>
                <p><a href='https://tbdigimenu.in/'>tbdigimenu</a> © Copyright 2023 | Powered By <a href='https://trailblazingdigiwebs.com/'>Trailblazing Digiwebs - Premium Web Solutions</a> </p>
            </div>
            <div className='cr-right'>
                <p><a href='#'>Privacy Policy</a> | <a href='#'>Terms & Conditions</a></p>
            </div>

        </div>
    </div>
  )
}
