import React from 'react'

import { PhoneCopyright } from "./PhoneCopyright";
import logoWhite from '../Assets/tbdigimenu-white.png';
import phone from '../Assets/telephone.png';
import whatsapp from '../Assets/whatsapp-white.png';



export const PhoneFooter = () => {
  return (
    <div className='footer'>
        <div className='footer-container'>
            <div className='footerLogo'>
              <img 
                  src={logoWhite}
                  alt="tbdigimenu"
                  height="60"
                   />
            </div>
            <p className='footerText'>Get Digital Menu With QR Code For Your Restaurant</p>
            <div className='callToAction'>
                <a href="tel:917838550257" target="_blank" className='socialLink' ><img src={phone} alt="Call Us" /></a>
                <a href="http://wa.me/917838550257?text=Hi%20Trailblazing%20Digiwebs" target="_blank" className='socialLink' ><img src={whatsapp} alt="WhatsApp Us" /></a>
            </div>
            <div class="white-border"></div>
            <div className='footer-social'>
                <a href="https://www.instagram.com/trailblazingdigiwebs/" target="_blank" className='socialLink' ><img src="https://uploads-ssl.webflow.com/61486616615e390a3069ed26/61486616615e39269d69ed7a_social-instagram-white.svg" alt="Instagram" /><div>Instagram</div></a>
                <a href="https://www.facebook.com/trailblazingdigiwebs/" target="_blank" className='socialLink' ><img src="https://uploads-ssl.webflow.com/61486616615e390a3069ed26/61486616615e39320d69ed6d_social-facebook-white.svg" alt="Facebook" /><div>Facebook</div></a>
            </div>
        </div>
        <PhoneCopyright/>
    </div>

  )
}
