import { Home } from "./Pages/Home"; 
import { PhoneHome } from "./PhonePages/PhoneHome"; 

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
      <div className="desktopView">
        <Home/>
      </div>
      <div className="phoneView">
        <PhoneHome/>
      </div>


    </>
  );
}

export default App;
